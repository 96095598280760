<template>
  <div class="ShopForm">
    <div v-loading="loading"></div>
    <p class="ShopForm-title">填写并确认信息</p>
    <div>
      <div v-if="!loading" class="Shop-form">
        <el-form :model="shopForm" :rules="rules" ref="ruleForm">
          <div v-if="shopForm.type != 0">
            <div class="ShopForm-tip flex2 violet">填写收货地址</div>
            <el-form-item label="地址信息：" prop="province" class="flex">
              <el-select v-model="shopForm.province" placeholder="请选择省" @change="select0(shopForm.province)" class="mr">
                <el-option v-for="item in address" :label="item.name" :key="item.id" :value="item.name"></el-option>
              </el-select>
              <el-select v-model="shopForm.city" placeholder="请选择市" @change="select1(shopForm.city)" class="mr">
                <el-option v-for="item in address0" :label="item.name" :key="item.id" :value="item.name"></el-option>
              </el-select>
              <el-select v-model="shopForm.area" placeholder="请选择区">
                <el-option v-for="item in address1" :label="item.name" :key="item.id" :value="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="详细地址：" prop="siteInfo" class="flex">
              <el-input v-model="shopForm.siteInfo" placeholder="请输入详细地址信息，如道路，门牌号，小区，楼栋号，单元等信息"></el-input>
            </el-form-item>
            <el-form-item label="邮政编码：" prop="postcode" class="flex">
              <el-input v-model="shopForm.postcode" placeholder="请填写邮编"></el-input>
            </el-form-item>
            <el-form-item label="收货人姓名：" prop="name" class="flex">
              <el-input v-model="shopForm.name" placeholder="长度不超过25个字符" maxlength="25"></el-input>
            </el-form-item>
            <el-form-item label="手机号码：" prop="mobile" class="flex">
              <el-select v-model="shopForm.areacode" placeholder="请选择区" class="mr" style="width:200px;max-width: 200px;min-width: 122px">
                <el-option label="中国大陆+86" :value="'中国大陆'+86"></el-option>
              </el-select>
              <el-input v-model="shopForm.mobile" placeholder="请输入手机号码" maxlength="25"></el-input>
            </el-form-item>
            <div class="defaultAddress">
              <el-checkbox v-model="shopForm.isDefault" class="flex">设置为默认收货地址</el-checkbox>
            </div>
            <div class="ShopForm-tip flex2 violet">交易验证</div>
          </div>
          <el-form-item label="手机号码：" class="flex" v-if="shopForm.type != 0">
            <el-input v-model="Info.phone" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号码：" class="flex" v-else  prop="mobile">
            <el-input v-model="shopForm.mobile" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="验证码：" prop="code" class="flex">
            <div class="flex2" style="width: 100%">
              <el-input v-model="shopForm.code" placeholder="请输入验证码" maxlength="6"></el-input>
              <el-button class="getCode ml" v-if="codeShow" @click="getPhoneCode">获取短信验证码</el-button>
              <el-button class="getCode ml" v-else disabled>{{ count }} 秒后重试</el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="info" size="medium" :loading="loading1" @click="submitForm('ruleForm')" class="Immediately-change">立即兑换</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="alert" class="shade flex0">
      <div class="success-alert">
        <img v-if="clientWidth > 750" src="@/assets/PC/shop/pc-alert.png" alt="">
        <img v-else src="@/assets/PC/shop/mb-alert.png" alt="" class="mb-alert">
        <div class="success-alert-text">
          <i class="el-icon-circle-close co-fff cur" @click="close"></i>
          <div class="tc pc-alert-c">
            <img src="@/assets/PC/shop/pc-alert-c.png" alt="">
            <h1>兑换成功</h1>
            <div class="pc-alert-price flex0">实际支付<p class="violet">{{Allprices}}金币</p></div>
          </div>
          <div class="pc-alert-operation flex3">  
            <el-button @click="cancel">返回商城</el-button>
            <el-button @click="examine" type="info">查看订单</el-button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { GetUserInfo, GetSystemMessageTop4 } from "@/api/profile.js";
import * as Shop from "@/api/Shop.js";
import { getCode } from "@/api/register.js";
import { debounce } from "@/utils/common";

export default {
  name: "ShopForm",
  data() {
    return {
      Info: this.$store.state.Infos,      // !个人信息
      loading: true,                      // !loading
      loading1: false,                    // !立即兑换loading
      address: [],                        // !地址筛选
      address0: [],                       // !市级筛选
      address1: [],                       // !区级筛选
      shopForm: {
        productId: '',                    // !商品Id
        num: 0,                           // !数量
        province: '',                     // !省
        city: '',                         // !市
        area: '',                         // !区
        siteInfo: '',                     // !详细地址
        areacode: "中国大陆+86",           // !区
        mobile: '',                       // !默认地址手机号
        postcode: '',                     // !邮政编码
        name: '',                         // !收货人姓名
        code:'',                          // !手机验证码
        isDefault: false,                 // !是否为默认地址
        type: 0,                          // !是否为电子商品
      },
      count: 0,                           // !短信验证码倒计时
      codeShow: true,                     // !获取验证码按钮显示
      alert: false,                       // !兑换成功弹窗
      Allprices: 0,                       // !实际支付金币
      rules: {
        province: [{ required: true, message: "请选择省", trigger: "blur" }],
        siteInfo: [{ required: true, message: "请输入详细地址信息，如道路，门牌号，小区等信息", trigger: "blur" }],
        name: [{ required: true, message: "请输入收货人姓名", trigger: "blur" }],
        mobile: [
                  { required: true, message: "请输入手机号码", trigger: "blur" },
                  {pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,message: "请输入正确的手机号",trigger: "blur"}
                ],
        code: [{ required: true, message: "请输入手机验证码", trigger: "blur" }],
        postcode: [{ required: false, pattern: /^[1-9]\d{5}$/, message: "请输入正确的邮政编码", trigger: "blur"}]
      }
    }
  },
  mounted() {
    // !获取省市地址信息
    Shop.GetAllCity().then( res => {
      this.address = res;
    }).then( () => {
      // !获取默认地址
      Shop.GetDefaultAddress().then(res => {
        if (res) {
          if(res.data) {
            this.shopForm.province = res.data.province;
            this.shopForm.city = res.data.city;
            this.shopForm.area = res.data.area;
            this.shopForm.siteInfo = res.data.siteInfo;
            this.shopForm.areacode = res.data.areacode;
            if(res.data.mobile){
              this.shopForm.mobile = res.data.mobile;
            }
            this.shopForm.postcode = res.data.postcode;
            this.shopForm.name = res.data.name;

            if(this.shopForm.province) {
              this.address.filter(v => {
                if(v.name == this.shopForm.province) {
                  this.address0 = v.children;
                  this.address0.filter(v0 => {
                    if(v0.name == this.shopForm.city) {
                      this.address1 = v0.children
                    }
                  })
                }
              })
            }
          }
          
        }
      })
    })

    // !获取id
    if(this.$route.query.id) {
      this.shopForm.productId = this.$route.query.id;
    }else {
      this.shopForm.productId = '';
    }
    if(this.$route.query.num) {
      this.shopForm.num = this.$route.query.num * 1;
    }else {
      this.shopForm.num = 1;
    }

    // !判断商品限制一个
    Shop.ValidateOnlyBuyOne({ productId: this.shopForm.productId, num: this.shopForm.num }).then(res => {
      if(res.status != 1){
        this.$toast(res.message);
        this.shopForm.num = 1
      }
    })

    // !判断是否为电子商品
    Shop.ValidateProductType({ productId: this.shopForm.productId }).then(res => {
      if(res.status == 1) {
        this.shopForm.type = res.data;
        if(res.data == 0){
          GetUserInfo().then(res => {
            if(res.status == 1){
              this.shopForm.mobile = res.data.phone
            }
          })
        }
      } else {
        this.$toast(res.message);
      }
      this.loading = false;
    })

  },
  methods: {
    // !省级联动
    select0(child) {
      this.address.filter(v => {
        if(v.name == child) {
          this.address0 = v.children;
          this.address1 = v.children[0].children;
          this.shopForm.city = v.children[0].name;
          this.shopForm.area = v.children[0].children[0].name;
        }
      })
    },
    // !市级联动
    select1(child) {
      this.address0.filter(v => {
        if(v.name == child) {
          this.address1 = v.children;
          this.shopForm.area = v.children[0].name;
        }
      })
    },
    // !立即兑换
    submitForm(shopForm) {
      this.$refs[shopForm].validate((valid) => {
        if (valid) {
          if(this.$store.state.token){
            this.loading1 = true;
            Shop.ValidateOrder({productId: this.shopForm.productId}).then((res) => {
              if(res.status == 1){
                if(res){
                  Shop.BuyProduct(this.shopForm).then(res => {
                    if(res.status == 1) {
                      this.alert = true;
                      if(this.$store.state.token){
                        // !获取个人中心同步金币数量
                        GetUserInfo().then(res => {
                          if(res.status === 1) {
                            this.$store.dispatch('Profile', res.data);
                            GetSystemMessageTop4().then(res => {
                              if(res.status == 1) {
                                this.$store.dispatch('MsgTop4', res.data);
                              }else {
                                this.$toast(res.message);
                              }
                            })
                          } else {
                            this.$toast(res.message)
                            this.$router.push('/Login');
                            this.$store.commit('$_setStorage', '')
                          }
                        })
                      }
                      this.Allprices = res.message
                    }else {
                      this.$toast(res.message);
                      this.loading1 = false;
                    }
                  })
                }
              }else {
                this.$toast(res.message);
                this.loading1 = false;  
                this.$router.push("/Home/ShoppingMall/ShopList");
              }
            })
          } else {
            this.$toast('请先登录后再来兑换吧！')
          }
        }
      })
    },
    // !获取验证码
    getPhoneCode() {
      // const phone = this.Info.phone;
      let phone
      if(this.shopForm.type == 0){
        phone = this.shopForm.mobile
      }else{
        phone = this.Info.phone
      }
      debounce( () => {
        getCode({ phone: phone, type: 3, isCheck: false }).then((res) => {
          if (res.status == 1) {
            this.$toast("发送成功！");
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.codeShow = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.codeShow = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$toast(res.message);
          }
        });
      },1000)
    },
    // !关闭弹窗
    close() {
      this.loading1 = false;
      this.alert = false;
    },
    // !返回商城
    cancel() {
      this.$router.push("/Home/ShoppingMall/ShopList");
    },
    // !查看订单
    examine() {
      this.$router.push("/Home/Profile/ForRecord");
    }
  },
  computed: {
    clientWidth () {
      return this.$store.state.clientWidth;
    },
    Infos() {
      return this.$store.state.Infos;
    },
  },
  watch: {
    Infos(val) {
      this.Info = val;
    },
    $route: {
      handler() {
        // !判断商品限制一个
        Shop.ValidateOnlyBuyOne({ productId: this.shopForm.productId, num: this.shopForm.num }).then(res => {
          if(res.status != 1){
            this.$toast(res.message);
            this.shopForm.num = 1
          }
        })
      },
      // 深度观察监听
      deep: true,
    },
  },
}
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }

  .ShopForm {
    width: 1200px; min-height: 300px; box-shadow: 0 0 10px #ccc; margin: 40px auto; padding: 30px;
    .ShopForm-title {font-size: 18px; font-weight: 500;}
    .ShopForm-tip {height: 41px; background: #edcdf1; font-size: 16px; padding-left: 16px;margin: 30px 0;}
    
    .Shop-form {margin: 30px;}
    .el-form .el-form-item{width: 100%;}
    .el-form .el-form-item__label {min-width: 120px;}
    .el-form .el-form-item__content {width: 100%; display: flex; justify-content: space-between;}

    .defaultAddress {margin: 0 0 25px 120px;}
    .defaultAddress .el-checkbox__input.is-checked+.el-checkbox__label {color: #6b18a8;}
    .defaultAddress .el-checkbox {align-items: center;}

    .Immediately-change {width: 196px; height: 52px; font-weight: bold;}
    .Immediately-change span {font-size: 21px;}

    .shade {width: 100%;height: 100%;background: rgba(0,0,0,.6);position: fixed; top: 0; left: 0; z-index: 10000;}
    .success-alert {position: relative;}
    .success-alert-text {position: absolute; left: 0; top: 0; width: 100%;height: 100%;}
    .success-alert-text i {font-size: 26px;position: absolute; right: 25px;top: 25px;}

    .mb-alert {display: none;}
    .pc-alert-c {margin-top: 123px;}
    .pc-alert-c h1 {font-size: 38px;margin: 39px 0 20px 0;}

    .pc-alert-price, .pc-alert-price p{font-size: 24px;font-weight: 500;}

    .pc-alert-operation {padding: 0 139px;margin-top: 50px;}
    .pc-alert-operation button {width: 236px;height: 46px;}
    .pc-alert-operation button span {font-size: 16px;}
  }
  @media (max-width: 750px) {
    .ShopForm {width: auto; margin: vw(40); padding: vw(30);
      .ShopForm-title {font-size: vw(28)}
      .ShopForm-tip {margin: vw(20) 0; height: vw(48); font-size: vw(24); padding-left: vw(11);}
      
      .Shop-form {margin: 0;}
      .el-form-item {display: block;}
      .el-form .el-form-item__label {min-width: vw(115); line-height: vw(48); font-size: vw(22);}
      .el-form-item__error {font-size: vw(22);}

      .defaultAddress {margin: 0;}
      .Immediately-change {width: 100%; height: vw(65); border-radius: 0;}
      .Immediately-change span {font-size: vw(28);}

      .success-alert {margin: 0 vw(40);}
      .mb-alert {max-width: 100%; display: block; margin: 0 auto;}
      .success-alert-text i{top: vw(30); right: vw(30);}
      
      .pc-alert-c {margin-top: vw(130);}
      .pc-alert-c img {width: vw(46);}
      .pc-alert-c h1 {font-size: vw(38); margin: vw(39) 0 vw(20) 0;}
      .pc-alert-price, .pc-alert-price p{font-size: vw(24); font-weight: 500;}

      .pc-alert-operation {padding: 0 vw(49); margin-top: vw(35);}
      .pc-alert-operation button {width: vw(236); height: vw(46);padding: 0;}
      .pc-alert-operation button span {font-size: vw(24);}
    }
  }
</style>